import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import {
  Grid,
  Snackbar,
  TextField,
  Button,
  AlertTitle,
  Modal,
  Box,
  Toolbar,
  InputBase,
  Avatar,
  Typography,
  IconButton,
  Stack,
  Autocomplete,
  Alert,
  Chip,
} from "@mui/material";
import {
  Close as CloseIcon,
  Send as SendIcon,
  Notifications,
  AttachFile,
} from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";

import { AddComment, Comments } from "../services/CommentService";
import { fetchUserDetails } from "../services/LoginService";
import { getMyProfileById } from "../services/MyProfileService";
import { Attachment } from "../services/AttachmentService"; // Importing the service function
import { getDossierById } from "../services/DossierService";
import uploadFileService from "../services/UploadFileService";

const Notification = () => {
  const { id } = useParams();
  const [dossier, setDossier] = useState(null);
  const [isMobile, setIsMobile] = useState(true);
  const [notificationOpen, setNotificationOpen] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const [comment, setComment] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [myProfile, setMyProfile] = useState(null);
  const [comments, setComments] = useState([]);
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [alertSeverity, setAlertSeverity] = React.useState("success");
  const [alertMessage, setAlertMessage] = React.useState("");
  const [commentAttachShowModal, setCommentAttachShowModal] = useState(false);
  const pathdossier = "dossier";
  const [selectedFiles, setSelectedFiles] = useState({
    green_card_photo: [],
    glass_photo: [],
    vehicle_registration_photo: [],
  });

  // Fetch user details
  useEffect(() => {
    const getUserDetails = async () => {
      try {
        const data = await fetchUserDetails();
        setUserDetails(data);

        const myProfile = await getMyProfileById(data.usertype, data.userid);
        setMyProfile(myProfile);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    };

    getUserDetails();
  }, []);

  useEffect(() => {
    const getComments = async () => {
      try {
        const data = await Comments(id);
        setComments(data);
      } catch (error) {
        console.error("Error fetching comments:", error);
      }
    };

    if (id) {
      getComments();
    }
  }, [id]);

  // Fetch dossier data
  useEffect(() => {
    const fetchDossier = async () => {
      try {
        const dossierData = await getDossierById(id);
        setDossier(dossierData);
        setSelectedFiles({
          green_card_photo: parseFiles(dossierData.green_card_photo),
          glass_photo: parseFiles(dossierData.glass_photo),
          vehicle_registration_photo: parseFiles(
            dossierData.vehicle_registration_photo
          ),
        });
      } catch (error) {
        console.error("Error fetching dossier data:", error);
      }
    };

    if (id) {
      fetchDossier();
    }
  }, [id]);

  const parseFiles = (fileString) => {
    if (!fileString) return [];
    return fileString.split("|").map((filePath) => {
      const fileName = filePath.split("/").pop();
      return { name: fileName, path: filePath };
    });
  };

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth <= 600);
    }

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleToggle = () => {
    setNotificationOpen(!notificationOpen);
  };

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };
  const showAlert = (message, severity = "success") => {
    setAlertMessage(message);
    setAlertSeverity(severity);
    setAlertOpen(true);
  };

  const validateComment = () => {
    if (!comment.trim()) {
      showAlert("Le commentaire ne peut pas être vide.", "error");
      return false;
    }
    if (comment.length > 200) {
      showAlert("Le commentaire ne peut pas dépasser 200 caractères.", "error");
      return false;
    }
    return true;
  };

  const handleSendComment = async () => {
    if (!validateComment()) {
      return;
    }

    const formData = {
      comment: comment,
      comment_date: new Date().toISOString(),
    };

    try {
      await AddComment(formData, userDetails.usertype, userDetails.userid, id);
      setComment("");
      const updatedComments = await Comments(id);
      setComments(updatedComments);
      showAlert("Commentaire envoyé avec succès.", "success");
    } catch (error) {
      console.error("Error sending comment:", error);
      showAlert("Erreur lors de l'envoi du commentaire.", "error");
    }
  };

  // HANDLE ALERT  FUNCTIONS
  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  const getUsername = (comment) => {
    if (comment.admin_first_name && comment.admin_last_name) {
      return `${comment.admin_first_name} ${comment.admin_last_name}`;
    } else if (comment.cc_first_name && comment.cc_last_name) {
      return `${comment.cc_first_name} ${comment.cc_last_name}`;
    } else if (comment.garage_first_name && comment.garage_last_name) {
      return `${comment.garage_first_name} ${comment.garage_last_name}`;
    } else if (
      comment.gestionnaire_first_name &&
      comment.gestionnaire_last_name
    ) {
      return `${comment.gestionnaire_first_name} ${comment.gestionnaire_last_name}`;
    } else {
      return "Unknown";
    }
  };

  // THE ATTACHMENT SECTION //

  function handleCommentAttachShowModal() {
    setCommentAttachShowModal(true);
  }
  const handleCommentAttachCloseModal = () => {
    setCommentAttachShowModal(false);
  };

  // HANDLE FILE CHANGE FUNCTIONS
const handleFileChange = (name, event) => {
  setSelectedFiles((prevFiles) => {
    const updatedFiles = [...(prevFiles[name] || [])]; // Initialize with existing files or an empty array
    Array.from(event.target.files).forEach((file) => {
      const fieldPrefix = name === "glass_photo" || name === "vehicle_registration_photo"
        ? "green_card_photo"
        : name;

      // Add the fieldPrefix as a custom property to the file object
      const fileWithField = new File([file], file.name, { type: file.type });
      fileWithField.fieldPrefix = fieldPrefix;

      updatedFiles.push(fileWithField); // Append new file
    });

    return {
      ...prevFiles,
      [name]: updatedFiles,
    };
  });
};


  const handleRemoveFile = (name, index) => {
    setSelectedFiles((prevFiles) => {
      const newFiles = [...prevFiles[name]];
      newFiles.splice(index, 1);
      return { ...prevFiles, [name]: newFiles };
    });
  };

  const handleEdit = async () => {
    try {
      const dossier_id = dossier.dossier_id;

      const allFiles = Object.values(selectedFiles).flat();

      // Upload files and get the response
      const fileUploadResponse = await uploadFileService(allFiles, dossier);

      // Base path to be used for relative paths
      const basePath = `uploads/${pathdossier}/`;

      const fileFields = [
        "glass_photo",
        "green_card_photo",
        "vehicle_registration_photo",
      ];

      // Map the file paths to the corresponding form fields
      const filePaths = {};
      fileUploadResponse.filePaths.forEach((filePath, index) => {
        // Extract the field name from the file's name
        const fileName = allFiles[index].name;
        const fileNameSegments = fileName.split("-");
        const fileField = fileNameSegments[fileNameSegments.length - 2]; // Adjusted to match your file naming scheme

        if (fileFields.includes(fileField)) {
          if (!filePaths[fileField]) {
            filePaths[fileField] = [];
          }
          filePaths[fileField].push(`${basePath}${filePath.split("\\").pop()}`);
        }
      });

      // Prepare object for file paths only
      const files = {};
      fileFields.forEach((fileField) => {
        files[fileField] = filePaths[fileField]
          ? filePaths[fileField].join("|")
          : "";
      });

      // Prepare object for file names with base path
      const fileNamesWithBasePath = {};
      Object.entries(selectedFiles).forEach(([fieldName, files]) => {
        fileNamesWithBasePath[fieldName] = files.map((file) => {
          if (typeof file === "object" && file.name) {
            // Return file name with base path
            return `${basePath}${file.name}`;
          } else if (typeof file === "string") {
            // Keep existing string (assuming it's a path)
            return file;
          }
          return "";
        });
      });
      // Log data being sent to backend

      // Update backend via Attachment service to unset files
      const attachmentResponse = await Attachment(
        dossier_id,
        fileNamesWithBasePath
      );

      if (attachmentResponse) {
        window.location.reload();
        setCommentAttachShowModal(false);
        setAlertMessage("Files updated successfully.");
        setAlertSeverity("success");
        setAlertOpen(true);
      } else {
        console.error("Failed to update files in the database");
        setAlertMessage("Failed to update files.");
        setAlertSeverity("error");
        setAlertOpen(true);
      }
    } catch (error) {
      console.error("Error updating files:", error);
      setAlertMessage("Error updating files.");
      setAlertSeverity("error");
      setAlertOpen(true);
    }
  };

  // COMPONENTS FUNCTION
  const FileInput = ({ name, label }) => (
    <Stack spacing={2} alignItems="center">
      <Box sx={{ display: "flex", alignItems: "start", width: "100%" }}>
        <Autocomplete
          multiple
          limitTags={1}
          sx={{ width: "90%" }}
          size="small"
          id={`file-input-${name}`}
          options={[]}
          freeSolo
          value={selectedFiles[name] || []}
          getOptionLabel={(file) =>
            file.name
              ? file.name
              : typeof file === "string"
              ? file.split("/").pop()
              : ""
          }
          renderTags={(value, getTagProps) =>
            value.map((file, index) => {
              const fileName = file.name
                ? file.name
                : typeof file === "string"
                ? file.split("/").pop()
                : "";
              const chipKey = `${name}-${index}-${Math.floor(
                10000 + Math.random() * 90000
              )}`;

              return (
                <Chip
                  key={chipKey} // Ensure key is unique for each Chip
                  label={fileName}
                  {...getTagProps({ index })}
                  deleteIcon={
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => handleRemoveFile(name, index)}>
                      <DeleteIcon />
                    </IconButton>
                  }
                  onDelete={() => handleRemoveFile(name, index)}
                  sx={{
                    maxWidth: "200px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                />
              );
            })
          }
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              variant="outlined"
              label={label}
              inputProps={{
                ...params.inputProps,
                readOnly: true,
              }}
            />
          )}
        />
        <IconButton
          component="label"
          aria-label="upload file"
          htmlFor={`file-upload-${name}`}
          sx={{ width: "32px", height: "32px" }}>
          <AttachFile />
          <input
            type="file"
            id={`file-upload-${name}`}
            multiple
            onChange={(event) => {
              handleFileChange(name, event);
            }}
            style={{ display: "none" }}
          />
        </IconButton>
      </Box>
    </Stack>
  );

  return (
    <Box>
      {/* Notification Button */}
      <Toolbar
        sx={{
          backgroundColor: "rgba(255, 34, 77, 0.8)",
          position: "fixed",
          bottom: 20,
          right: 20,
          zIndex: 1000,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: 5,
          height: 5,
          borderRadius: "80%",
          "&:hover": {
            backgroundColor: "#ff224d",
          },
        }}>
        <IconButton onClick={handleToggle} style={{ color: "#fff" }}>
          <Notifications />
        </IconButton>
      </Toolbar>

      {notificationOpen && (
        <Box
          sx={{
            borderLeft: "2px solid #002654",
            width: isMobile ? "100%" : 400,
            backgroundColor: "#fff",
            color: "#002654",
            position: "fixed",
            top: 90,
            bottom: 0,
            right: 0,
            zIndex: 1000,
            overflowY: "auto",
            transition: "width 0.3s ease",
            display: "flex",
            flexDirection: "column",
          }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              paddingRight: 1,
            }}>
            <IconButton onClick={handleToggle} style={{ color: "#002654" }}>
              <CloseIcon />
            </IconButton>
          </Box>

          <Box sx={{ flex: "1", overflowY: "auto" }}>
            {comments.map((comment) => (
              <Box
                key={comment.comment_id}
                sx={{
                  display: "flex",
                  marginBottom: 2,
                  marginLeft: 2,
                }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    marginBottom: 1,
                    maxWidth: "calc(100% - 8px)", // Full width of parent - margin
                    wordWrap: "break-word", // Allow word wrapping
                  }}>
                  <Typography variant="subtitle2">
                    {getUsername(comment)}
                  </Typography>
                  <Box
                    sx={{
                      backgroundColor: "#002654",
                      borderRadius: "16px",
                      color: "#f5f5f5",
                      padding: "8px 8px",
                      marginLeft: 2,
                      marginRight: 2,
                    }}>
                    <Typography variant="body2">{comment.comment}</Typography>
                  </Box>
                  <Typography
                    variant="caption"
                    sx={{ color: "grey", display: "flex", alignItems: "end" }}>
                    {new Date(comment.comment_date).toLocaleString()}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>

          <Box
            sx={{
              backgroundColor: "#002654",
              borderTop: "1px solid #fff",
              padding: "8px",
              display: "flex",
              alignItems: "center",
            }}>
            <textarea
              placeholder="Entrez votre commentaire"
              name="comment"
              value={comment}
              onChange={handleCommentChange}
              rows={3} // Adjust rows as needed
              maxLength={200} // Limit to 200 characters
              style={{
                backgroundColor: "#fff",
                borderRadius: "4px", // Adjusted border radius
                padding: "8px",
                width: "100%", // Full width
                maxWidth: "520px", // Maximum width
                maxHeight: "90px", // Maximum height
                border: "none", // Remove border
                outline: "none", // Remove outline on focus
                resize: "none", // Disable resizing
                marginBottom: "8px",
              }}
            />

            <IconButton
              onClick={handleCommentAttachShowModal}
              sx={{ backgroundColor: "#002654", color: "#fff" }}>
              <AttachFile />
            </IconButton>
            <IconButton
              onClick={handleSendComment}
              sx={{
                backgroundColor: "#002654",
                color: "#fff",
              }}>
              <SendIcon />
            </IconButton>
          </Box>
        </Box>
      )}

      <Modal
        open={commentAttachShowModal}
        onClose={handleCommentAttachShowModal}
        aria-labelledby="rendezvous-modal-title"
        aria-describedby="rendezvous-modal-description">
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <FileInput label="Photo du vitrage" name="glass_photo" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FileInput
                label="Photo de la carte verte"
                name="green_card_photo"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FileInput
                label="Photo de la carte grise"
                name="vehicle_registration_photo"
              />
            </Grid>
          </Grid>

          <Box
            sx={{
              mt: 2,
              display: "flex",
              justifyContent: "space-between",
            }}>
            <Button
              variant="outlined"
              color="error"
              onClick={handleCommentAttachCloseModal}>
              Annuler
            </Button>
            <Button variant="contained" color="primary" onClick={handleEdit}>
              Ajouter des Documents
            </Button>
          </Box>
        </Box>
      </Modal>

      <Snackbar
        open={alertOpen}
        autoHideDuration={5000}
        onClose={handleAlertClose}
        >
          <Alert onClose={handleAlertClose} severity={alertSeverity}>
            <AlertTitle>
              {alertSeverity === "success" ? "Succès" : "Erreur"}
            </AlertTitle>
            {alertMessage}
          </Alert>
      </Snackbar>
    </Box>
  );
};

export default Notification;
