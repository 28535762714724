const baseURL = process.env.REACT_APP_BACK_BASE_URL;
export const LoginUser = async (formData) => {
  try {
    const response = await fetch(`${baseURL}/LoginUser`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    });

    if (response.ok) {
      return response.json();
    } else {
      const errorMessage = await response.json();
      throw new Error(errorMessage.message);
    }
  } catch (error) {
    throw error;
  }
};

export const fetchUserDetails = async () => {
  try {
    const response = await fetch(`${baseURL}/UserDetails`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.ok) {
      return response.json();
    } else {
      const errorMessage = await response.json();
      throw new Error(errorMessage.message);
    }
  } catch (error) {
    throw error;
  }
};
