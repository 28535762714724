// services/MyProfileService.js
const baseURL = process.env.REACT_APP_BACK_BASE_URL;

export const getMyProfileById = async (User, userid) => {
  try {
    const response = await fetch(`${baseURL}/MyProfile/${User}/${userid}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      const errorMessage = await response.json();
      console.error(
        `Failed to retrieve ${User} data. Error message:`,
        errorMessage.message
      );
      throw new Error(errorMessage.message);
    }
  } catch (error) {
    console.error(`Error retrieving ${User} data:`, error);
    throw error;
  }
};
