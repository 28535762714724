import React, { useState, useEffect } from "react";
import { AppBar, Avatar, Toolbar, Link, Box } from "@mui/material";
import Logo from "../assets/long_logo.svg";

function Navbar() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <AppBar
      position="fixed"
      sx={{
        zIndex: 1,
        backgroundColor: "#002654",
        height: "90px",
        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
        margin: "0 auto",
        padding: "8",
        fontFamily: "Poppins, sans-serif",
      }}>
      <Toolbar sx={{ alignItems: "center", justifyContent: "center" }}>
         <Avatar
            variant="square"
            alt="Bleu Glass"
            src={Logo}
            sx={{
              mt: 3,
              width: isMobile ? 150 : 250,
              height: isMobile ? 25 : 50,
              borderRadius: "12px",
            }}
          />
        {/* <Box sx={{ display: "flex", alignItems: "center",ml: 2 }}>
          <Link
            href="https://bleu-glass.fr"
            color="inherit"
            underline="hover"
            sx={{
              mr: 2,
              color: "white",
              fontSize: "1rem",
              "&:hover": {
                color: "rgba(210, 41, 52, 1)",
              },
            }}
            target="_blank"
            rel="noopener noreferrer">
            Principale
          </Link>
          {!isMobile && (
            <Box>
              <Link
                href="https://bleu-glass.fr/a-propos"
                color="inherit"
                underline="hover"
                sx={{
                  mr: 2,
                  color: "white",
                  fontSize: "1rem",
                  "&:hover": {
                    color: "rgba(210, 41, 52, 1)",
                  },
                }}
                target="_blank"
                rel="noopener noreferrer">
                À-propos
              </Link>
              <Link
                href="https://bleu-glass.fr/politique-de-confidentialite"
                color="inherit"
                underline="hover"
                sx={{
                  mr: 2,
                  color: "white",
                  fontSize: "1rem",
                  "&:hover": {
                    color: "rgba(210, 41, 52, 1)",
                  },
                }}
                target="_blank"
                rel="noopener noreferrer">
                Politique de confidentialité
              </Link>
              <Link
                href="https://bleu-glass.fr/contact"
                color="inherit"
                underline="hover"
                sx={{
                  mr: 2,
                  color: "white",
                  fontSize: "1rem",
                  "&:hover": {
                    color: "rgba(210, 41, 52, 1)",
                  },
                }}
                target="_blank"
                rel="noopener noreferrer">
                Contact
              </Link>
            </Box>
          )}
        </Box> */}
      </Toolbar>
    </AppBar>
  );
}

export default Navbar;
