// AttachmentServices.js

const baseURL = process.env.REACT_APP_BACK_BASE_URL;

export const Attachment = async (dossier_id, updatedFilePaths) => {
  try {
    const response = await fetch(`${baseURL}/Attachment/${dossier_id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(updatedFilePaths),
    });

    if (response.ok) {
      return await response.json();
    } else {
      throw new Error("Failed to update files in the database");
    }
  } catch (error) {
    console.error("Error updating files:", error);
    throw error;
  }
};
