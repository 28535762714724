// FolderTableService.js
const baseURL = process.env.REACT_APP_BACK_BASE_URL;

export const getDossierData = async (usertype) => {
  try {
    const response = await fetch(`${baseURL}/FolderTable`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ usertype }),
    });

    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      const errorMessage = await response.json();
      console.error(
        "Failed to retrieve dossier data. Error message:",
        errorMessage.message || "Unknown error"
      );
      throw new Error(errorMessage.message || "Unknown error");
    }
  } catch (error) {
    console.error("Error retrieving dossier data:", error);
    throw error;
  }
};
