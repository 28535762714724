import React, { useState, useEffect } from "react";
import { Chip } from "@mui/material";
import { fetchUserDetails } from "../services/LoginService";

const StatusChip = ({ status, onClick }) => {
  const [userDetails, setUserDetails] = useState(null);

  useEffect(() => {
    const getUserDetails = async () => {
      try {
        const data = await fetchUserDetails();
        setUserDetails(data);
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    };

    getUserDetails();
  }, []);

  if (!userDetails) {
    // Return a loader or nothing if userDetails are not yet fetched
    return null;
  }

  const { usertype } = userDetails;

  const statusColors = {
    "En cours": "#1E90FF",
    "BDG oui": "#002654",
    Signature: "#00BFFF",
    "Attente de pose": "#87CEEB",
    "Rendez vous": "#4682B4",
    "Pose terminé": "#32CD32",
    "Envoie de CCA": "#3CB371",
    Relance: "#FF9E0D",
    "Dossier terminé": "#ff224d",
    Annuler: "#D61E1E",
  };

  // Helper function to get radiant color style
  const getRadiantStyle = (color) => ({
    background: `linear-gradient(45deg, ${color} 20%,  ${color} 90%)`,
    boxShadow: `0px 4px 12px rgba(0, 0, 0, 0.3)`,
    color: "#FFFFFF",
    width: "140px",
    textAlign: "center",
    fontWeight: "bold",
  });

  const getTraiterStyle = () => ({
    ...getRadiantStyle("#D3D3D3"),
    color: "#000000",
    backgroundColor: "#D3D3D3",
    "&:hover": {
      transform: "scale(1.05)",
    },
  });

  if (status && status.includes("Annuler")) {
    return <Chip label="Annulé" sx={getRadiantStyle("#C21B1B")} />;
  }

  if (status === null) {
    if (usertype !== "call_center" && usertype !== "garage") {
      return <Chip label="Traiter" sx={getTraiterStyle()} onClick={onClick} />;
    } else {
      return (
        <Chip label="Non traité"  sx={{
          color:"#404040",
          boxShadow: `0px 4px 12px rgba(0, 0, 0, 0.3)`,
          width: "140px",
          textAlign: "center",
          fontWeight: "bold" ,background: `linear-gradient(45deg, #D9D9D9 20%,  #D9D9D9 90%)`}} disabled />
      );
    }
  } else {
    if (
      ( usertype === "call_center") &&
      ["Envoie de CCA", "Relance"].includes(status)
    ) {
      return (
        <Chip
          label="Pose terminé"
          sx={getRadiantStyle(statusColors["Pose terminé"])}
        />
      );
    } else {
      return <Chip label={status} sx={getRadiantStyle(statusColors[status])} />;
    }
  }
};

export default StatusChip;
