const baseURL = process.env.REACT_APP_BACK_BASE_URL;

export const PasswordService = async ({
  usertype,
  userid,
  currentPassword,
  newPassword,
}) => {
  try {
    const response = await fetch(`${baseURL}/PasswordChange`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        usertype,
        userid,
        currentPassword,
        newPassword,
      }),
    });

    if (response.ok) {
      return response.json();
    } else {
      throw new Error(
        `Failed to change password: ${response.status} ${response.statusText}`
      );
    }
  } catch (error) {
    console.error("Error changing password:", error);
    throw error;
  }
};

export const updateUserDetails = async ({
  usertype,
  userid,
  firstName,
  lastName,
  email,
}) => {
  try {
    const response = await fetch(`${baseURL}/UpdateUserDetails`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        usertype,
        userid,
        firstName,
        lastName,
        email,
      }),
    });

    if (response.ok) {
      return response.json();
    } else {
      throw new Error(
        `Failed to update user details: ${response.status} ${response.statusText}`
      );
    }
  } catch (error) {
    console.error("Error updating user details:", error);
    throw error;
  }
};
