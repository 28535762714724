// services/CreateUserService.js

const baseURL = process.env.REACT_APP_BACK_BASE_URL;
export const CreateUser = async (formData, usertype, userid) => {
  try {
    // console.log("FormData:", formData); // Log the formData before sending
    const response = await fetch(`${baseURL}/CreateUser`, {
      method: "POST",
      body: JSON.stringify({ ...formData, usertype, userid }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.ok) {
      const jsonResponse = await response.json();
      return jsonResponse;
    } else {
      const errorMessage = await response.json();
      throw new Error(errorMessage.message);
    }
  } catch (error) {
    throw error;
  }
};
