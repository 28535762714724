// services/CreateFolderService.js

const baseURL = process.env.REACT_APP_BACK_BASE_URL;
export const CreateFolder = async (formData, userid, usertype) => {
  try {
    const response = await fetch(`${baseURL}/CreateFolder`, {
      method: "POST",
      body: JSON.stringify({ ...formData, userid, usertype }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.ok) {
      const jsonResponse = await response.json();
      return jsonResponse;
    } else {
      const errorMessage = await response.json();
      throw new Error(errorMessage.message);
    }
  } catch (error) {
    throw error;
  }
};
