// Home.js
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import * as XLSX from "xlsx";
import { DataGrid } from "@mui/x-data-grid";

import { TextField, Autocomplete, Box, Chip, Button } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import LinkIcon from "@mui/icons-material/Link";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

import Header from "../components/Header";
import Sidebar from "../components/SideBar";

import { getDossierData } from "../services/FolderTableService";
import { DossierUpdate } from "../services/DossierUpdateService";
import { fetchUserDetails } from "../services/LoginService";

function Home() {
  const [dossierData, setDossierData] = useState([]);
  const [UserDetails, setUserDetails] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [sortModel, setSortModel] = React.useState([
    {
      field: "dossier_id",
      sort: "desc",
    },
  ]);
  // USER DETAILS
  useEffect(() => {
    const getUserDetails = async () => {
      try {
        const data = await fetchUserDetails();
        setUserDetails(data);
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    };

    getUserDetails();

    // Fetch dossier data after user details are fetched
    if (UserDetails) {
      fetchDossierData();
    }
  }, [UserDetails]);

  const { userid, usertype } = UserDetails || {};

  // DOSSIER FETCH
  const fetchDossierData = async () => {
    try {
      const data = await getDossierData(usertype);
      const formattedData = data.map((row) => ({
        ...row,
        id: row.dossier_id,
        garage: `${row.garage_first_name || ""} ${
          row.garage_last_name || ""
        }`.trim(),
        gestionnaire: `${row.gestionnaire_first_name || ""} ${
          row.gestionnaire_last_name || ""
        }`.trim(),
      }));

      setDossierData(formattedData);
    } catch (error) {
      console.error("Error fetching dossier data:", error);
    }
  };

  // TRAITER DOSSIER
  const handleTraiterDossier = async (dossierId) => {
    try {
      await DossierUpdate(dossierId, { status: "En cours" }, userid, usertype);

      const updatedData = dossierData.map((dossier) => {
        if (dossier.id === dossierId) {
          return { ...dossier, status: "En cours" };
        }
        return dossier;
      });
      setDossierData(updatedData);
    } catch (error) {
      console.error("Error updating dossier status:", error);
    }
  };

  // Helper function to get radiant color style
  const getRadiantStyle = (color) => ({
    background: `linear-gradient(45deg, ${color} 20%, ${color} 90%)`,
    boxShadow: `0px 4px 12px rgba(0, 0, 0, 0.3)`,
    color: "#FFFFFF",
    width: "140px",
    textAlign: "center",
    fontWeight: "bold",
    textTransform: "capitalize",
    transition: "transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out",
    "&:hover": {
      transform: "scale(1.05)",
      boxShadow: `0px 6px 14px rgba(0, 0, 0, 0.4)`,
    },
  });
  const renderTraiterDossierButton = (params) => {
    const dossierId = params.row.dossier_id;
    const status = params.row.status;

    // Define colors for each status
    const statusColors = {
      "En cours": "#1E90FF",
      "BDG oui": "#002654",
      Signature: "#00BFFF",
      "Attente de pose": "#87CEEB",
      "Rendez vous": "#4682B4",
      "Pose terminé": "#32CD32",
      "Envoie de CCA": "#3CB371",
      Relance: "#FF9E0D",
      "Dossier terminé": "#ff224d",
      Annuler: "#D61E1E",
    };

    // Check if status includes "Annuler"
    if (status && status.includes("Annuler")) {
      return <Chip label="Annulé" sx={getRadiantStyle("#D61E1E")} />;
    }

    if (status === null) {
      return (
        <Chip
          label="Traiter"
          sx={{
            ...getRadiantStyle("#D3D3D3"),
            color: "#000000",
            backgroundColor: "#D3D3D3",
            "&:hover": {
              backgroundColor: "#C0C0C0",
              transform: "scale(1.05)",
              boxShadow: `0px 6px 14px rgba(0, 0, 0, 0.3)`,
            },
          }}
          onClick={() => handleTraiterDossier(dossierId, status)}
        />
      );
    } else {
      return <Chip label={status} sx={getRadiantStyle(statusColors[status])} />;
    }
  };

  // LINK DOSSIER
  const renderNameOrCompany = (params) => {
    const dossierId = params.row.dossier_id;
    const nameOrCompany = params.value;
    const firstName = params.row.first_name; // Assuming the data includes first_name

    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Link
          to={`/Dossier/${dossierId}`}
          style={{
            color: "#1976d2", // Blue color
            textDecoration: "none",
            display: "flex",
            alignItems: "center",
            "&:hover": {
              textDecoration: "underline",
            },
          }}>
          <LinkIcon sx={{ marginRight: "4px" }} />{" "}
          {`${nameOrCompany} ${firstName}`}
        </Link>
      </Box>
    );
  };

  // Custom cell renderer for 'created_by_full_name'
  const renderCreatedByFullName = (params) => {
    let color = "";
    let roleLabel = "";

    switch (params.row.created_by) {
      case "admin":
        color = "red"; // Example color for admin
        roleLabel = "Ad";
        break;
      case "gestionnaire":
        color = "blue"; // Example color for gestionnaire
        roleLabel = "Ge";
        break;
      case "call_center":
        color = "pink"; // Example color for call center
        roleLabel = "Cc";
        break;
      case "garage":
        color = "orange"; // Example color for garage
        roleLabel = "Ga";
        break;
      // Add more cases for other roles as needed
      default:
        color = "grey";
        roleLabel = "Unknown";
        break;
    }

    const fullName = params.value;
    const spaceIndex = fullName.lastIndexOf(" ");
    const userName = fullName.substring(0, spaceIndex);

    return (
      <span>
        <span>{userName}</span>
        <span style={{ color: color }}> ({roleLabel})</span>
      </span>
    );
  };

  // Function to format the date
  const renderDateCreated = (params) => {
    const date = new Date(params.value);
    return date.toLocaleDateString(); // Format date as per locale, you can customize as needed
  };

  // Function to format the date
  const renderDateRendezVous = (params) => {
    const date = new Date(params.value);

    // Check if the date is '1/1/1970'
    if (date.getTime() === new Date("1970-01-01T00:00:00Z").getTime()) {
      return "";
    }

    return date.toLocaleDateString(); // Format date as per locale, you can customize as needed
  };

  //COLUMNS
  const columns = [
    {
      field: "dossier_id",
      headerName: "N° Dossier",
      width: 70,
      resizable: true,
    },
    {
      field: "name_or_company",
      width: 200,
      headerName: "Nom ou Entreprise",
      renderCell: renderNameOrCompany,
      resizable: true,
    },
    {
      field: "status",
      headerName: "Statut Bleu Glass",
      width: 160,
      renderCell: renderTraiterDossierButton,
      resizable: true,
    },
    {
      field: "vehicle_model",
      width: 160,
      headerName: "Modèle de Véhicule",
      resizable: true,
    },
    {
      field: "license_plate",
      width: 100,
      headerName: "Plaque de matriculation",
      resizable: true,
    },
    {
      field: "insurance_name",
      headerName: "Nom de l'assurance",
      width: 160,
      resizable: true,
    },
    {
      field: "postal_code",
      headerName: "Code Postal",
      resizable: true,
      width: 100,
    },
    { field: "garage_name", headerName: "Garage", width: 100, resizable: true },
    {
      field: "appointment_date",
      headerName: "Date de rendez-vous",
      renderCell: renderDateRendezVous,
      width: 100,
      resizable: true,
    },
    {
      field: "cc_call_center_name",
      headerName: "Centre d'Appel",
      width: 160,
      resizable: true,
    },
    {
      field: "gestionnaire",
      width: 160,
      headerName: "Gestionnaire",
      resizable: true,
    },
    {
      field: "created_by_full_name",
      headerName: "Créé par",
      renderCell: renderCreatedByFullName,
      width: 160,
      resizable: true,
    },
    {
      field: "date_created",
      width: 100,
      headerName: "Date de création",
      renderCell: renderDateCreated,
      resizable: true, // Use the custom render function
    },
  ];

  const exportToExcel = (data) => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Dossiers");
    XLSX.writeFile(workbook, "dossiers.xlsx");
  };

  const exportSelectedRowToExcel = () => {
    if (selectedRow) {
      exportToExcel([selectedRow]);
    } else {
      alert("Please select a row to export.");
    }
  };

  return (
    <Box component="div">
      <Header />
      <Box sx={{ marginTop: "120px", display: "flex" }}>
        <Sidebar />
        <Box
          sx={{
            width: window.innerWidth < 768 ? "99%" : "93%",
            height: "75vh",
            marginLeft: window.innerWidth < 768 ? "5px" : "260px",
            marginRight: "5px",
          }}
          component="div">
          <Box
            sx={{
              display: "flex",
              marginBottom: 2,
              gap: 1,
              alignContent: "center",
              alignItems: "center",
            }}>
            <Autocomplete
              freeSolo
              disableClearable
              options={[]}
              sx={{
                width: 300,
                height: 32,
                marginBottom: 1,
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  placeholder="Rechercher..."
                  InputProps={{
                    ...params.InputProps,
                    type: "search",
                    endAdornment: (
                      <SearchIcon
                        sx={{ color: "action.active", cursor: "pointer" }}
                      />
                    ),
                  }}
                />
              )}
            />
            {usertype === "admin" && (
              <>
                <Button
                  variant="contained"
                  sx={{
                    marginLeft: { xs: 0, sm: 2 },
                    marginTop: { xs: 2, sm: 0 },
                    width: { xs: "100%", sm: "auto" },
                    backgroundColor: "green",
                    "&:hover": {
                      backgroundColor: "darkgreen",
                    },
                    fontSize: { xs: "0.5rem", sm: "0.75rem" },
                    padding: { xs: "3px 6px", sm: "8px 10px" },
                  }}
                  onClick={() => exportToExcel(dossierData)}
                  startIcon={
                    <FileDownloadIcon
                      sx={{ fontSize: { xs: "0.5rem", sm: "0.75rem" } }}
                    />
                  }>
                  Exporter Tout
                </Button>

                <Button
                  variant="contained"
                  sx={{
                    marginLeft: { xs: 0, sm: 2 },
                    marginTop: { xs: 2, sm: 0 },
                    width: { xs: "100%", sm: "auto" },
                    backgroundColor: "green",
                    "&:hover": {
                      backgroundColor: "darkgreen",
                    },
                    fontSize: { xs: "0.5rem", sm: "0.75rem" },
                    padding: { xs: "4px 6px", sm: "8px 10px" },
                  }}
                  onClick={exportSelectedRowToExcel}
                  startIcon={
                    <FileDownloadIcon
                      sx={{ fontSize: { xs: "0.5rem", sm: "0.75rem" } }}
                    />
                  }>
                  Exporter la sélection
                </Button>
              </>
            )}
          </Box>

          <DataGrid
            rows={dossierData}
            columns={columns}
            sortModel={sortModel}
            onSortModelChange={(model) => setSortModel(model)}
            onRowClick={(params) => {
              setSelectedRow(params.row);
            }}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default Home;
