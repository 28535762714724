import React, { useEffect, useState } from 'react';
import Chip from '@mui/material/Chip';

const WebSocketComponent = () => {
  const [connectedClients, setConnectedClients] = useState(0);

  useEffect(() => {
    const socket = new WebSocket(process.env.REACT_APP_BACK_BASE_URL.replace(/^http/, 'ws'));

    socket.onopen = () => {
      console.log('WebSocket connected');
    };

    socket.onmessage = (event) => {
      try {
        const message = JSON.parse(event.data);
        if (message.type === 'connectedClients') {
          setConnectedClients(message.data);
        }
      } catch (error) {
        console.error('Error parsing WebSocket message:', error);
      }
    };

    socket.onclose = () => {
      console.log('WebSocket disconnected');
    };

    return () => {
      socket.close();
    };
  }, []);

  const getStatusColor = () => {
    if (connectedClients > 0) {
      return "#38761d"; // Green color for connected
    } else {
      return "#cc0000"; // Red color for disconnected
    }
  };

  return (
    <Chip
      label={`Utilisateurs connectés: ${connectedClients}`}
      style={{
        backgroundColor: getStatusColor(),
        color: 'white',
        fontWeight: 'bold',
      }}
    />
  );
};

export default WebSocketComponent;
