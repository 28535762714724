// DossierService.js
const baseURL = process.env.REACT_APP_BACK_BASE_URL;
export const getDossierById = async (id) => {
  try {
    const response = await fetch(`${baseURL}/Dossier/${id}`);
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      const errorMessage = await response.json();
      throw new Error(errorMessage.message);
    }
  } catch (error) {
    throw error;
  }
};
