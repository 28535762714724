// services/CommentService.js
const baseURL = process.env.REACT_APP_BACK_BASE_URL;
export const AddComment = async (formData, usertype, userid, dossierId) => {
  try {
    const response = await fetch(`${baseURL}/AddComment`, {
      method: "POST",
      body: JSON.stringify({ ...formData, usertype, userid, dossierId }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.ok) {
      const jsonResponse = await response.json();
      return jsonResponse;
    } else {
      const errorMessage = await response.json();
      throw new Error(errorMessage.message);
    }
  } catch (error) {
    throw error;
  }
};

export const Comments = async (dossierId) => {
  try {
    const response = await fetch(`${baseURL}/Comments/${dossierId}`);
    if (response.ok) {
      const jsonResponse = await response.json();
      return jsonResponse;
    } else {
      throw new Error("Failed to fetch comments");
    }
  } catch (error) {
    throw error;
  }
};
