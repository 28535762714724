const baseURL = process.env.REACT_APP_BACK_BASE_URL;
export const DossierUpdate = async (dossierId,updates, userid, usertype) => {
  try {
    const response = await fetch(`${baseURL}/DossierUpdate`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ dossierId,...updates, userid, usertype }),
    });

    if (response.ok) {
    } else {
      const errorMessage = await response.json();
      throw new Error(errorMessage.message);
    }
  } catch (error) {
    console.error("Failed to update dossier:", error);
    throw error;
  }
};
