import React from "react";
import Box from "@mui/material/Box";
import { keyframes } from "@mui/system";
import Logo from "../assets/bleu-glass.svg";
import carImage from "../assets/car.png";

const moveCar = keyframes`
  0% {
    transform: translateX(-200px);
  }
  100% {
    transform: translateX(200px);
  }
`;

const LoadingIndicator = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        position: "relative",
      }}>
      <img
        src={Logo}
        alt="Logo"
        style={{ marginBottom: "20px", width: 140, height: 140 }}
      />
      <Box
        component="img"
        src={carImage}
        alt="Car"
        sx={{
          width: 200,
          height: 100,
          animation: `${moveCar} 0.7s linear infinite`,
        }}
      />
    </Box>
  );
};

export default LoadingIndicator;
