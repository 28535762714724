import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Stack,
  Paper,
  List,
  ListItemButton,
  ListItemText,
  Grid,
  TextField,
  Button,
  Select,
  MenuItem,
  Avatar,
  FormControl,
  Input,
  InputLabel,
  IconButton,
  Autocomplete,
  Alert,
  AlertTitle,
  Snackbar,
} from "@mui/material";

import { Visibility } from "@mui/icons-material";
import { VisibilityOff } from "@mui/icons-material";
import { InputAdornment } from "@mui/material";
import { OutlinedInput } from "@mui/material";
import { Chip } from "@mui/material";
import {
  Person as PersonIcon,
  Edit as EditIcon,
  AttachFile as AttachFileIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";

import { fetchUserDetails } from "../services/LoginService";
import { CreateUser } from "../services/CreateUserService";
import uploadFileService from "../services/UploadFileService";
import { getUserData } from "../services/UserTableService";

import Sidebar from "../components/SideBar";
import Header from "../components/Header";

const CreateUserForm = () => {
  // CONST
  const navigate = useNavigate();
  const [selectedusertype, setSelectedusertype] =
    React.useState("gestionnaire");
  const [profileFilePath, setProfileFilePath] = useState("");
  const [profileFile, setProfileFile] = useState(null);
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [alertSeverity, setAlertSeverity] = React.useState("success");
  const [alertMessage, setAlertMessage] = React.useState("");
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const [UserDetails, setUserDetails] = useState(null);
  const [ccCallCenterNameList, setCcCallCenterNameList] = useState([]);
  const [ccRole, setCcRole] = useState("agent");

  // DATA LIST
  useEffect(() => {
    // Fetch call center names when component mounts
    fetchCallCenterNames();
  }, []);

   const fetchCallCenterNames = async () => {
    try {
      // Check if UserDetails is defined and has the userid property
      if (UserDetails && UserDetails.userid) {
        const callCenters = await getUserData("call_center", UserDetails.userid);
        const uniqueNames = getUniqueNames(callCenters);
        setCcCallCenterNameList(uniqueNames);
      }
    } catch (error) {
      console.error("Error fetching call center names:", error);
      // Handle error
    }
  };
  
  useEffect(() => {
    // Fetch call center names only when UserDetails is defined
    if (UserDetails) {
      fetchCallCenterNames();
    }
  }, [UserDetails]);

  const getUniqueNames = (callCenters) => {
    const nameSet = new Set();
    const uniqueNames = [];

    callCenters.forEach((center) => {
      if (!nameSet.has(center.cc_call_center_name)) {
        nameSet.add(center.cc_call_center_name);
        uniqueNames.push(center.cc_call_center_name);
      }
    });

    return uniqueNames;
  };

  const [selectedFiles, setSelectedFiles] = useState({
    // gestionnaire
    gestionnaire_cin: [],
    gestionnaire_profile: [],
    // call center
    cc_cin: [],
    cc_rc: [],
    cc_profile: [],
    // garage
    garage_kbis: [],
    garage_identity: [],
    garage_profile: [],
  });
  const [formData, setFormData] = useState({
    //gestionnaire
    gestionnaire_first_name: "",
    gestionnaire_last_name: "",
    gestionnaire_role: "",
    gestionnaire_open_time: "",
    gestionnaire_close_time: "",
    gestionnaire_email: "",
    gestionnaire_password: "",
    gestionnaire_cin: "",
    gestionnaire_profile: "",
    //call center
    cc_call_center_name: "",
    cc_first_name: "",
    cc_last_name: "",
    cc_role: "",
    cc_open_time: "",
    cc_close_time: "",
    cc_email: "",
    cc_password: "",
    cc_cin: "",
    cc_rc: "",
    cc_profile: "",
    //Garage
    garage_name: "",
    garage_first_name: "",
    garage_last_name: "",
    garage_role: "",
    garage_email: "",
    garage_password: "",
    garage_kbis: "",
    garage_identity: "",
    garage_profile: "",
  });

  // HANDLE USER TYPE CHANGE FUNCTIONS
  const handleusertypeSelect = (usertype) => {
    setSelectedusertype(usertype);
    setFormData({
      //gestionnaire
      gestionnaire_first_name: "",
      gestionnaire_last_name: "",
      gestionnaire_role: "",
      gestionnaire_open_time: "",
      gestionnaire_close_time: "",
      gestionnaire_email: "",
      gestionnaire_password: "",
      gestionnaire_cin: "",
      gestionnaire_profile: "",
      //call center
      cc_call_center_name: "",
      cc_first_name: "",
      cc_last_name: "",
      cc_role: "",
      cc_open_time: "",
      cc_close_time: "",
      cc_email: "",
      cc_password: "",
      cc_cin: "",
      cc_rc: "",
      cc_profile: "",
      //Garage
      garage_name: "",
      garage_first_name: "",
      garage_last_name: "",
      garage_role: "",
      garage_email: "",
      garage_password: "",
      garage_kbis: "",
      garage_identity: "",
      garage_profile: "",
    });
  };

  // HANDLE DATA CHANGE FUNCTIONS
  const handleChange = (event) => {
    const { name, value } = event.target;

    if (name === "cc_role") {
      setCcRole(value); // Update cc_role state
      setFormData((prevData) => ({
        ...prevData,
        cc_role: value, // Also update cc_role in formData
      }));
      // console.log("cc_role:", value); // Log cc_role value when it changes
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // HANDLE Autocomplete CHANGE FUNCTIONS
  const handleAutocompleteChange = (event, newValue) => {
    setFormData((prev) => ({ ...prev, cc_call_center_name: newValue }));
  };

  // HANDLE FILE CHANGE FUNCTIONS
  const handleFileChange = (name, event) => {
    let firstName, lastName;

    switch (selectedusertype) {
      case "gestionnaire":
        firstName = formData.gestionnaire_first_name || "empty_first_name";
        lastName = formData.gestionnaire_last_name || "empty_last_name";
        break;
      case "call_center":
        firstName = formData.cc_first_name || "empty_first_name";
        lastName = formData.cc_last_name || "empty_last_name";
        break;
      case "garage":
        firstName = formData.garage_first_name || "empty_first_name";
        lastName = formData.garage_last_name || "empty_last_name";
        break;
      default:
        firstName = "empty_first_name";
        lastName = "empty_last_name";
    }

    setSelectedFiles((prevFiles) => {
      // const updatedFiles = [...prevFiles[name]];
      const updatedFiles = [...(prevFiles[name] || [])];
      Array.from(event.target.files).forEach((file) => {
        const fileExtension = file.name.split(".").pop();
        const randomNum = Math.floor(10000 + Math.random() * 90000); // Generate a random 5-digit number
        const newFileName = `${firstName}${lastName}-${name}-${randomNum}.${fileExtension}`;
        const newFile = new File([file], newFileName, { type: file.type });
        updatedFiles.push(newFile);
      });

      return {
        ...prevFiles,
        [name]: updatedFiles,
      };
    });
  };
  const handleProfileFileChange = (name, event) => {
    const file = event.target.files[0];
    if (file) {
      let firstName, lastName;

      switch (selectedusertype) {
        case "gestionnaire":
          firstName = formData.gestionnaire_first_name || "empty_first_name";
          lastName = formData.gestionnaire_last_name || "empty_last_name";
          break;
        case "call_center":
          firstName = formData.cc_first_name || "empty_first_name";
          lastName = formData.cc_last_name || "empty_last_name";
          break;
        case "garage":
          firstName = formData.garage_first_name || "empty_first_name";
          lastName = formData.garage_last_name || "empty_last_name";
          break;
        default:
          firstName = "empty_first_name";
          lastName = "empty_last_name";
      }

      const fileExtension = file.name.split(".").pop();
      const randomNum = Math.floor(10000 + Math.random() * 90000); // Generate a random 5-digit number
      const newFileName = `${firstName}${lastName}-${name}-${randomNum}.${fileExtension}`;
      const newFile = new File([file], newFileName, { type: file.type });

      setProfileFile(newFile);
      setProfileFilePath(URL.createObjectURL(newFile));

      // Update the formData to reflect the profile file path
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: newFileName,
      }));
    }
  };

  const handleRemoveFile = (name, index) => {
    setSelectedFiles((prevFiles) => {
      const newFiles = [...prevFiles[name]];
      newFiles.splice(index, 1);
      return { ...prevFiles, [name]: newFiles };
    });
  };

  // HANDLE ALERT  FUNCTIONS
  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  useEffect(() => {
    const getUserDetails = async () => {
      try {
        const data = await fetchUserDetails();
        setUserDetails(data);
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    };

    getUserDetails();
  }, []);

  // HANDLE SUBMIT FUNCTION
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const allFiles = Object.values(selectedFiles).flat();
      if (profileFile) allFiles.push(profileFile);

      const fileUploadResponse = await uploadFileService(
        allFiles,
        selectedusertype
      );

      const basePath = `uploads/user/${selectedusertype}/`;

      const filePaths = {};

      fileUploadResponse.filePaths.forEach((filePath, index) => {
        const fileField = allFiles[index].name.split("-")[1];
        if (!filePaths[fileField]) {
          filePaths[fileField] = [];
        }
        filePaths[fileField].push(`${basePath}${filePath.split("\\").pop()}`);
      });

      Object.keys(filePaths).forEach((fileField) => {
        formData[fileField] = filePaths[fileField].join("|");
      });

      // Ensure the profile field is updated with the correct path
      if (profileFile) {
        const profileField = `${selectedusertype}_profile`;
        formData[profileField] = `${basePath}${profileFile.name
          .split("\\")
          .pop()}`;
      }
      // console.log(formData)

      const response = await CreateUser(
        formData,
        selectedusertype,
        UserDetails.userid
      );

       // Navigate to the respective table based on user type
    if (selectedusertype === "garage") {
      navigate("/GarageTable");
    } else if (selectedusertype === "gestionnaire") {
      navigate("/GestionnaireTable");
    } 
    // else if (selectedusertype === "call_center") {
    //   navigate("/CallCenterTable");
    // }

      setAlertMessage(
        `L'utilisateur de type ${selectedusertype} a été créé avec succès.`
      );
      setAlertSeverity("success");
      setAlertOpen(true);
    } catch (error) {
      setAlertMessage(error.message);
      setAlertSeverity("error");
      setAlertOpen(true);
    }
  };

  // COMPONENTS  FUNCTION
  const FileInput = ({ name, label }) => (
    <Stack spacing={2} alignItems="center">
      <Box sx={{ display: "flex", alignItems: "start", width: "100%" }}>
        <Autocomplete
          multiple
          limitTags={1}
          sx={{ width: "90%" }}
          size="small"
          id={`file-input-${name}`}
          options={[]}
          freeSolo
          // value={selectedFiles[name]}
          value={selectedFiles[name] || []}
          getOptionLabel={(file) => file.name}
          renderTags={(value, getTagProps) =>
            value.map((file, index) => {
              const { key, ...tagProps } = getTagProps({ index });
              return (
                <Chip
                  key={key}
                  label={file.name}
                  {...tagProps}
                  deleteIcon={
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => handleRemoveFile(name, index)}>
                      <DeleteIcon />
                    </IconButton>
                  }
                  onDelete={() => handleRemoveFile(name, index)}
                  sx={{
                    maxWidth: "200px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                />
              );
            })
          }
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              variant="outlined"
              label={label}
              inputProps={{
                ...params.inputProps,
                readOnly: true,
              }}
            />
          )}
        />
        <IconButton
          component="label"
          aria-label="upload file"
          htmlFor={`file-upload-${name}`}
          sx={{ width: "32px", height: "32px" }}>
          <AttachFileIcon />
        </IconButton>
        <input
          type="file"
          id={`file-upload-${name}`}
          multiple
          onChange={(event) => handleFileChange(name, event)}
          style={{ display: "none" }}
        />
      </Box>
    </Stack>
  );
  const ProfileInput = ({ name }) => (
    <Stack
      spacing={2}
      display="flex"
      flexDirection="row"
      justifyContent="center">
      <label
        htmlFor={`profile-upload-${name}`}
        style={{ position: "relative", display: "inline-block" }}>
        <Avatar
          alt="Profile Picture"
          src={profileFilePath || ""}
          sx={{ width: 64, height: 64 }}>
          {!profileFilePath && <PersonIcon />}
        </Avatar>
        <Input
          type="file"
          accept="image/*"
          id={`profile-upload-${name}`}
          onChange={(event) => handleProfileFileChange(name, event)}
          sx={{ display: "none" }}
        />
      </label>
      <IconButton
        component="label"
        htmlFor={`profile-upload-${name}`}
        sx={{
          width: 24,
          height: 24,
          bgcolor: "#002654",
          color: "white",
          "&:hover": {
            bgcolor: "#ff224d",
          },
          marginLeft: "-40px",
        }}>
        <EditIcon sx={{ fontSize: 16 }} />
      </IconButton>
    </Stack>
  );

  // FORM RENDER  FUNCTIONS
  const renderForm = () => {
    switch (selectedusertype) {
      // case "admin":
      //   return renderAdminForm();
      case "gestionnaire":
        return renderGestionnaireForm();
      case "call_center":
        return renderCallCenterForm();
      case "garage":
        return renderGarageForm();
      default:
        return null;
    }
  };

  const renderGestionnaireForm = () => {
    return (
      <Box>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <ProfileInput name="gestionnaire_profile" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              onChange={handleChange}
              id="gestionnaire_first_name"
              label="Prénom"
              name="gestionnaire_first_name"
              autoFocus
              size="small"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              onChange={handleChange}
              id="gestionnaire_last_name"
              label="Nom de famille"
              name="gestionnaire_last_name"
              size="small"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth size="small">
              <InputLabel id="gestionnaire_role_label">Rôle</InputLabel>
              <Select
                id="gestionnaire_role"
                labelId="gestionnaire_role_label"
                name="gestionnaire_role"
                value={formData.gestionnaire_role}
                label="Rôle"
                onChange={handleChange}
                required>
                <MenuItem value="normal">Gestionnaire Normal</MenuItem>
                <MenuItem value="supper">Super Gestionnaire</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          {/* <Grid item xs={6} sm={3}>
            <TextField
              required
              fullWidth
              onChange={handleChange}
              id="gestionnaire_open_time"
              label="Ouverture de compte"
              name="gestionnaire_open_time"
              value={formData.gestionnaire_open_time}
              type="time"
              InputLabelProps={{ shrink: true }}
              autoFocus
              size="small"
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <TextField
              required
              fullWidth
              onChange={handleChange}
              id="gestionnaire_close_time"
              label="Fermeture de compte"
              name="gestionnaire_close_time"
              value={formData.gestionnaire_close_time}
              type="time"
              InputLabelProps={{ shrink: true }}
              autoFocus
              size="small"
            />
          </Grid> */}
          <Grid item xs={12} sm={6}>
            <FileInput label="gestionnaire_cin" name="gestionnaire_cin" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              onChange={handleChange}
              id="gestionnaire_email"
              label="Email"
              name="gestionnaire_email"
              size="small"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth variant="outlined" size="small">
              <InputLabel htmlFor="gestionnaire_password">
                Mot de passe
              </InputLabel>
              <OutlinedInput
                id="gestionnaire_password"
                name="gestionnaire_password"
                type={showPassword ? "text" : "password"}
                value={formData.gestionnaire_password}
                onChange={handleChange}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end">
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Mot de passe"
              />
            </FormControl>
          </Grid>
        </Grid>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          sx={{
            mt: 2,
            backgroundColor: "#002654",
            "&:hover": {
              backgroundColor: "#ff224d",
            },
          }}>
          Créer
        </Button>
      </Box>
    );
  };
  const renderCallCenterForm = () => {
    return (
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ProfileInput name="cc_profile" />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl fullWidth size="small">
              <InputLabel id="cc_role_label">Rôle</InputLabel>
              <Select
                id="cc_role"
                labelId="cc_role_label"
                name="cc_role"
                value={ccRole} // Use ccRole state here
                label="Rôle"
                onChange={handleChange}
                required>
                <MenuItem value="agent">Agent du centre</MenuItem>
                <MenuItem value="gerant">Gérant du centre</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            {formData.cc_role === "gerant" ? (
              <TextField
                label="Call Center Name"
                name="cc_call_center_name"
                size="small"
                fullWidth
                value={formData.cc_call_center_name}
                onChange={handleChange}
              />
            ) : (
              <Autocomplete
                options={ccCallCenterNameList}
                getOptionLabel={(option) => option}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Call Center Name"
                    variant="outlined"
                    name="cc_call_center_name"
                    size="small"
                  />
                )}
                value={formData.cc_call_center_name}
                onChange={handleAutocompleteChange}
              />
            )}
          </Grid>
          {/* <Grid item xs={6} sm={3}>
            <TextField
              required
              fullWidth
              onChange={handleChange}
              id="cc_open_time"
              label="Ouverture de compte"
              name="cc_open_time"
              type="time"
              value={formData.cc_open_time}
              InputLabelProps={{ shrink: true }}
              autoFocus
              size="small"
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <TextField
              required
              fullWidth
              onChange={handleChange}
              id="cc_close_time"
              label="Fermeture de compte"
              name="cc_close_time"
              value={formData.cc_close_time}
              type="time"
              InputLabelProps={{ shrink: true }}
              autoFocus
              size="small"
            />
          </Grid> */}
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              onChange={handleChange}
              id="cc_first_name"
              label="Prénom"
              name="cc_first_name"
              autoFocus
              size="small"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              onChange={handleChange}
              id="cc_last_name"
              label="Nom de famille"
              name="cc_last_name"
              autoFocus
              size="small"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FileInput label="Carte d'identité " name="cc_cin" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FileInput label="Registre du commerce" name="cc_rc" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              onChange={handleChange}
              id="cc_email"
              label="Email"
              name="cc_email"
              autoFocus
              size="small"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth variant="outlined" size="small">
              <InputLabel htmlFor="cc_password">Mot de passe</InputLabel>
              <OutlinedInput
                id="cc_password"
                name="cc_password"
                type={showPassword ? "text" : "password"}
                value={formData.cc_password}
                onChange={handleChange}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end">
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Mot de passe"
              />
            </FormControl>
          </Grid>
        </Grid>
        <Button
          sx={{
            mt: 2,
            backgroundColor: "#002654",
            "&:hover": {
              backgroundColor: "#ff224d",
            },
          }}
          type="submit"
          variant="contained"
          color="primary"
          onClick={handleSubmit}>
          Créer
        </Button>
      </Box>
    );
  };
  const renderGarageForm = () => {
    return (
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ProfileInput name="garage_profile" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              onChange={handleChange}
              id="garage_name"
              label="Nom du garage"
              name="garage_name"
              autoFocus
              size="small"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth size="small">
              <InputLabel id="garage_role_label">Rôle</InputLabel>
              <Select
                id="garage_role"
                labelId="garage_role_label"
                name="garage_role"
                value={formData.garage_role}
                label="Rôle"
                onChange={handleChange}
                required>
                <MenuItem value="agent">Agent dans le garage</MenuItem>
                <MenuItem value="gerant">Gérant du garage</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              onChange={handleChange}
              id="garage_first_name"
              label="Prénom"
              name="garage_first_name"
              size="small"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              onChange={handleChange}
              id="garage_last_name"
              label="Nom de famille"
              name="garage_last_name"
              size="small"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FileInput label="KBIS du garage" name="garage_kbis" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FileInput label="Identité du garage" name="garage_identity" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              onChange={handleChange}
              id="garage_email"
              label="Email"
              name="garage_email"
              size="small"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth variant="outlined" size="small">
              <InputLabel htmlFor="garage_password">Mot de passe</InputLabel>
              <OutlinedInput
                id="garage_password"
                name="garage_password"
                type={showPassword ? "text" : "password"}
                value={formData.garage_password}
                onChange={handleChange}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end">
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Mot de passe"
              />
            </FormControl>
          </Grid>
        </Grid>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          sx={{
            mt: 2,
            backgroundColor: "#002654",
            "&:hover": {
              backgroundColor: "#ff224d",
            },
          }}>
          Créer
        </Button>
      </Box>
    );
  };

  // CORE RETURN
  return (
    <Box>
      <Header />
      <Box sx={{ marginTop: "100px", display: "flex" }}>
        <Sidebar />
        <Snackbar
          open={alertOpen}
          autoHideDuration={5000}
          onClose={handleAlertClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
          <Alert onClose={handleAlertClose} severity={alertSeverity}>
            <AlertTitle>
              {alertSeverity === "success" ? "Succès" : "Erreur"}
            </AlertTitle>
            {alertMessage}
          </Alert>
        </Snackbar>
        <Box
          sx={{
            width: window.innerWidth < 768 ? "95%" : "80%",
            marginLeft: window.innerWidth < 768 ? "10px" : "260px",
            marginRight: "10px",
          }}>
          <List
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "20px",
              color: "white",
              backgroundColor: "#002654",
              borderRadius: "8px 8px 0 0",
              padding: "0px",
            }}>
            <ListItemButton
              onClick={() => handleusertypeSelect("gestionnaire")}
              sx={{
                backgroundColor:
                  selectedusertype === "gestionnaire" ? "#ff224d" : "inherit",
                "&:hover": {
                  backgroundColor: "rgba(255, 34, 77, 0.8)",
                },
                borderRadius:
                  selectedusertype === "gestionnaire"
                    ? "8px 8px 0 0"
                    : "inherit",
              }}>
              <ListItemText primary="Gestionnaire" />
            </ListItemButton>
            <ListItemButton
              onClick={() => handleusertypeSelect("call_center")}
              sx={{
                backgroundColor:
                  selectedusertype === "call_center" ? "#ff224d" : "inherit",
                "&:hover": {
                  backgroundColor: "rgba(255, 34, 77, 0.8)",
                },
                borderRadius:
                  selectedusertype === "call_center"
                    ? "8px 8px 0 0"
                    : "inherit",
              }}>
              <ListItemText primary="Call Center" />
            </ListItemButton>
            <ListItemButton
              onClick={() => handleusertypeSelect("garage")}
              sx={{
                color: "white",
                backgroundColor:
                  selectedusertype === "garage" ? "#ff224d" : "inherit",
                "&:hover": {
                  backgroundColor: "rgba(255, 34, 77, 0.8)",
                },
                borderRadius:
                  selectedusertype === "garage" ? "8px 8px 0 0" : "inherit",
              }}>
              <ListItemText primary="Garage" />
            </ListItemButton>
          </List>
          <Paper elevation={3} sx={{ padding: "20px" }}>
            {selectedusertype && renderForm()}
          </Paper>
        </Box>
      </Box>
    </Box>
  );
};

export default CreateUserForm;
